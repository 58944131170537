<template>
  <div>
    <Panel
      module="Faturamento"
      title="Gerar Faturamento"
      :showFilter="false"
      :showSearch="false"
      :showButtons="false"
    >
      <div slot="content-main">
        <GenerateBilling />
      </div>
    </Panel>
  </div>
</template>
<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import GenerateBilling from '../../../components/modules/billing/generate-billing/GenerateBilling.vue'

import { mapMutations } from "vuex";

export default {
  name: "GenerateBillingView",
  components: {
    Panel,
    GenerateBilling
  },
  data() {
    return {
      rentId: this.$route.params.id,
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>