<template>
  <div class="main">
    <div v-if="loading">
      <br />
      <Loading type="line" :center="true" />
      <div>Aguarde, estamos gerando o faturamento...</div>
    </div>
    <div v-else>
      <div v-if="!success">
        <SelectOptionPeriodRent v-show="step == 1" :rentId="rentId" :showPeriodAll="true" />
        <DisplayTotalization title="TOTAL GERAL" :value="totalSelected" _style="color: #1b3a55;font-size:19px" />
        <Molded v-show="step == 1">
          <ItemsRentForInvoice :rentId="rentId" :periodRentId="periodRentId" />
        </Molded>
        <div class="div-alert">
          <Alert type="warning" v-if="totalSelected - sumPayment != 0 && step == 2">
            <span>
              Restante
              {{ difference | currency }}</span>
          </Alert>
        </div>
        <Molded v-if="step == 2">
          <PaymentCreate module="Rental" :totalValue="totalSelected" />
          <br />
          <PaymentsList />
        </Molded>
        <InvoiceOrTaxDocument v-show="step == 3" v-model="invoiceOrTaxDocument" />
        <div v-show="step == 4">
          <GenerateInvoice v-if="invoiceOrTaxDocument == 1" v-model="generateInvoice" />
          <GenerateTaxDocument v-if="invoiceOrTaxDocument == 2" :customerId="customerId" v-model="generateNfse" />
        </div>
        <br />
        <div class="text-center">
          <Button _key="btnClickedBack" v-if="step > 1" type="info" title="Voltar"
            classIcon="title fa-solid fa-circle-arrow-left" size="small" :clicked="back" />
          <Button v-if="step != 4" _key="btnClickedNext" type="info" title="Avançar"
            classIcon="title fas fa-arrow-circle-right" :disabled="disabled" size="medium" :clicked="next" />
          <Button v-if="step == 4" _key="btnGenerateBilling" type="success" title="Gerar Faturamento" size="large"
            :clicked="generateBilling" />
        </div>
      </div>
      <div v-else>
        <SuccessBilling :typeDocument="invoiceOrTaxDocument == 1 ? 'fatura' : 'nota'" :document="document"
          :rentId="rentId" />
      </div>
    </div>
  </div>
</template>

<script>

import InvoiceOrTaxDocument from "./InvoiceOrTaxDocument.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import DisplayTotalization from "@nixweb/nixloc-ui/src/component/rental/DisplayTotalization";
import ItemsRentForInvoice from "./ItemsRentForInvoice.vue";
import SelectOptionPeriodRent from "../../rental/period-rental/SelectOptionPeriodRent.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import GenerateInvoice from "./GenerateInvoice.vue";
import GenerateTaxDocument from './GenerateTaxDocument.vue';
import PaymentCreate from "../../shared/payment-items/PaymentCreate.vue";
import PaymentsList from "../../shared/payment-items/PaymentsList.vue";
import SuccessBilling from "./SuccessBilling.vue";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "GenerateBilling",
  components: {
    ItemsRentForInvoice,
    Molded,
    Button,
    Alert,
    Loading,
    SelectOptionPeriodRent,
    DisplayTotalization,
    PaymentCreate,
    PaymentsList,
    InvoiceOrTaxDocument,
    GenerateInvoice,
    SuccessBilling, GenerateTaxDocument
  },
  data() {
    return {
      document: {},
      rentId: this.$route.params.rentId,
      customerId: this.$route.params.customerId,
      periodRentId: "",
      selected: [],
      invoiceOrTaxDocument: 0,
      generateInvoice: {},
      generateNfse: {},
      step: 1,
      urlGenerate: "/api/v1/billing/generate-billing",
      urlGetAllWithoutLinkedFinance: "/api/v1/rental/payment-rent/get-all-without-linked-finance",
      success: false,
      loading: false,
    };
  },
  mounted() {
    this.getAllWithoutLinkedFinance();
  },
  beforeDestroy() {
    this.resetPayment();
  },
  computed: {
    ...mapGetters("generic", ["event"]),
    ...mapGetters("paymentShared", ["sumPayment"]),
    ...mapState("paymentShared", ["paymentsRent"]),
    difference() {
      return this.totalSelected.toFixed(2) - this.sumPayment.toFixed(2);
    },
    totalSelected() {
      const sum = this.selected.reduce((accumulator, obj) => {
        return accumulator + (obj.totalUnitValue + obj.totalUnitInsurance);
      }, 0);
      return sum;
    },
    disabled() {
      if (this.step == 1) {
        if (this.selected.length > 0) return false;
      }

      if (this.step == 2) {
        if (this.difference == 0) return false;
      }

      if (this.step == 3) {
        if (this.invoiceOrTaxDocument != 0) return false;
      }

      return true;
    },
    idsItemsRent() {
      let ids = [];
      this.selected.forEach((item) => {
        ids.push(item.id);
      });

      return ids;
    },
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    ...mapMutations("paymentShared", ["addPayment", "resetPayment"]),
    ...mapActions("generic", ["getApi", "postApi"]),
    next() {
      this.step++;
      this.removeLoading(["btnClickedNext"]);
    },
    back() {
      this.step--;
      this.removeLoading(["btnClickedBack"]);
    },
    generateBilling() {
      this.loading = true;
      let params = {
        url: this.urlGenerate,
        obj: this.invoiceOrTaxDocument == 1 ? this.createObjInvoice() : this.createObjNfse(),
      };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.success = true;
          this.document = response.content;
        }

        this.loading = false;
        this.removeLoading(["btnGenerateBilling"]);
      });
    },
    getAllWithoutLinkedFinance() {
      let params = {
        url: this.urlGetAllWithoutLinkedFinance,
        obj: { any: this.rentId },
      };
      this.getApi(params).then((response) => {
        response.content.forEach(item => {
          var obj = {
            id: item.id,
            typePayment: { id: item.typePaymentId, content: item.typePaymentName },
            dueDate: item.dueDate,
            value: item.value,
          }
          this.addPayment(obj);
        });
      });
    },
    createObjInvoice() {
      var obj = {
        invoiceOrTaxDocument: this.invoiceOrTaxDocument,
        rentId: this.rentId,
        otherCustomer: this.generateInvoice.otherCustomer,
        customerId: this.generateInvoice.customer.id,
        isAddProductRent: this.generateInvoice.isAddProductRent,
        isAddPeriodRent: this.generateInvoice.isAddPeriodRent,
        isAddPayment: this.generateInvoice.isAddPayment,
        description: this.generateInvoice.description,
        observation: this.generateInvoice.observation,
        paymentsRent: this.paymentsRent,
        idsItemsRent: this.idsItemsRent,
        descriptionNfse: this.generateNfse.description,
        taxation: this.generateNfse.taxation,
        value: this.totalSelected,
      };
      return obj;
    },
    createObjNfse() {
      var obj = {
        invoiceOrTaxDocument: this.invoiceOrTaxDocument,
        rentId: this.rentId,
        otherCustomer: this.generateNfse.otherCustomer,
        customerId: this.generateNfse.customer.id,
        paymentsRent: this.paymentsRent,
        idsItemsRent: this.idsItemsRent,
        descriptionNfse: this.generateNfse.description,
        emails: this.generateNfse.emails,
        taxation: this.generateNfse.taxation,
        tributacao: this.generateNfse.tributacao,
        value: this.totalSelected,
      };
      return obj;
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "selectedPeriodRent") {
          if (event.data.showAll) this.periodRentId = "";
          if (!event.data.showAll) this.periodRentId = event.data.period.id;
        }

        if (event.name == "itemsSelectedForInvoice") {
          this.selected = event.data;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.main {
  margin-top: 20px;
}

.div-alert {
  margin-bottom: 10px;
}
</style>