<template>
  <div>
    <br />
    <div class="text-center">
      <div class="div-molded">
        <Molded>
          <div v-if="typeDocument == 'fatura'" class="msg-success">Fatura Gerada com Sucesso</div>
          <div v-else class="msg-success">Nota Fiscal em Processamento</div>
          <div v-if="typeDocument == 'fatura'" class="icon-success">
            <i class="fa-light fa-circle-check"></i>
          </div>
          <div v-else class="icon-processing">
            <i class="fa-regular fa-hourglass-end"></i>
          </div>
          <Alert v-if="typeDocument == 'nota'" type="warning">
            <span>
              A nota está sendo <b>processada</b>, o retorno do município pode demorar <b>poucos segundos a vários
                minutos</b>, mas fique tranquilo(a), assim que autorizada enviaremos um <i
                class="fa-regular fa-envelope"></i> <b>e-mail
                notificando!</b>
            </span>
          </Alert>
          <div v-if="typeDocument == 'fatura' || document.listBillet.length > 0">
            <Button _key="btnPrint" type="info" title="Compartilhar" classIcon="fa-sharp fa-regular fa-share-nodes"
              size="small" :clicked="executePrint" />
          </div>
          <br />
        </Molded>
      </div>
    </div>
    <PrintPreview v-if="print" module="Billing" :rentId="rentId" :listBillet="document.listBillet"
      :invoiceId="document.id" :onHideModal="onHideModal" />
  </div>
</template>

<script>
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import PrintPreview from "../../adm/document/PrintPreview.vue";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "SuccessBilling",
  components: {
    Alert,
    Button,
    Molded,
    PrintPreview,
  },
  props: {
    typeDocument: String,
    rentId: String,
    document: Object,
  },
  data() {
    return {
      print: false,
      urlGetPdf: "/api/v1/tax-document/nfse/get-pdf",
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    ...mapActions("generic", ["getFileApi"]),
    executePrint() {
      this.print = true;
      this.removeLoading(["btnPrint"]);
    },
    onHideModal() {
      this.print = false;
    },
    getPdf() {
      let params = {
        url: this.urlGetPdf,
        obj: {
          idIntegracao: this.document.content.idIntegracao,
          fileName: `nfse`,
          fileType: "pdf"
        },
      };
      this.getFileApi(params).then(() => { });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "emailSent") {
          this.print = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.div-molded {
  width: 550px;
  margin: auto;
}

.msg-success {
  font-size: 18px;
}

.icon-success {
  font-size: 55px;
  color: #38a169;
  margin-top: 10px;
  margin-bottom: 15px;
}

.icon-processing {
  font-size: 35px;
  color: orange;
  margin-top: 10px;
  margin-bottom: 15px;
}
</style>