<template>
  <div>
    <div class="div-title">
      Nota Fiscal de Serviço

    </div>
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <RadioGroup title="Faturar para outro Cliente?" :options="[
            { text: 'Sim', value: true },
            { text: 'Não', value: false },
          ]" v-model="generateNfse.otherCustomer" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="9" xl="9" v-if="generateNfse.otherCustomer">
          <CustomerWithCreate v-model="generateNfse.customer" />
        </b-col>
      </b-row>
    </Molded>
    <br />
    <Molded>
      <b-row>
        <b-col sm="12">
          <Select title="Serviço" field="type" :required="true" url="/api/v1/tax-document/taxation/select-all"
            v-model="generateNfse.taxation" :showNewRegister="false" :heightNewRegister="250">
          </Select>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <Loading type="line" :center="false" v-if="loading" />
          <TextArea title="Descrição" field="description" :required="true" :maxLength="800"
            v-model="generateNfse.description" />
        </b-col>
      </b-row>
      <InputTagEmail v-model="generateNfse.emails" :customerId="customerId" />
      <br>
      <Tributacao :disabled="false" :nfse="generateNfse" v-model="generateNfse.tributacao" />
    </Molded>
    <br />
  </div>
</template>

<script>


import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import CustomerWithCreate from "../../rental/shared/CustomerWithCreate.vue";
import InputTagEmail from '../../tax-document/notification/InputTagEmail.vue'
import Tributacao from '../../tax-document/nfse/Tributacao.vue'

import { mapState, mapActions } from "vuex";

export default {
  name: "GenerateInvoice",
  props: ["customerId"],
  components: {
    Molded,
    RadioGroup,
    EditorHtml,
    CustomerWithCreate,
    Select,
    TextArea, Tributacao, InputTagEmail, Loading
  },
  data() {
    return {
      urlGetParameter: "/api/v1/tax-document/taxation/get-parameter",
      loading: false,
      generateNfse: {
        otherCustomer: false,
        customer: {},
        description: "",
        taxation: {},
        emails: [],
        tributacao: {
          retido: false,
          codigoCidadeIncidencia: "",
          descricaoCidadeIncidencia: "",
          tipoTributacao: { id: "", content: "" },
          aliquota: 0,
          cofins: 0,
          csll: 0,
          inss: 0,
          ir: 0,
          pis: 0,
          deducoes: 0,
        },
      },
    };
  },
  mounted() {
    this.$emit("input", this.generateNfse);
  },
  computed: {
    ...mapState("paymentShared", ["paymentsRent"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    getParameter() {
      this.loading = true;
      let params = {
        url: this.urlGetParameter,
        obj: {
          taxationId: this.generateNfse.taxation.id,
          rentId: this.$route.params.rentId,
          paymentsRent: this.paymentsRent,
        },
        notNotifyToast: true
      };
      this.postApi(params).then((response) => {
        this.generateNfse.description = response.content;
        this.loading = false;
      });
    },
  },
  watch: {
    generateNfse: {
      handler(generateNfse) {
        this.$emit("input", generateNfse);
      },
      deep: true,
    },
    'generateNfse.taxation': {
      handler(taxation) {
        this.getParameter();
        this.generateNfse.tributacao.tipoTributacao.id = taxation.tipoTributacao;
        this.generateNfse.tributacao.aliquota = taxation.aliquota;
      },
      deep: true,
    },
    'generateNfse.otherCustomer': {
      handler(otherCustomer) {
        if (!otherCustomer) this.customerId = this.$route.params.customerId;
      },
      deep: true,
    },
    'generateNfse.customer': {
      handler(customer) {
        this.customerId = customer.id;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.div-title {
  font-size: 16px;
  margin-left: 5px;
  margin-bottom: 20px;
}
</style>