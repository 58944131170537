var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('Molded',[_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"3","xl":"3"}},[_c('RadioGroup',{attrs:{"title":"Faturar para outro Cliente?","options":[
          { text: 'Sim', value: true },
          { text: 'Não', value: false } ]},model:{value:(_vm.generateInvoice.otherCustomer),callback:function ($$v) {_vm.$set(_vm.generateInvoice, "otherCustomer", $$v)},expression:"generateInvoice.otherCustomer"}})],1),(_vm.generateInvoice.otherCustomer)?_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"9","xl":"9"}},[_c('CustomerWithCreate',{model:{value:(_vm.generateInvoice.customer),callback:function ($$v) {_vm.$set(_vm.generateInvoice, "customer", $$v)},expression:"generateInvoice.customer"}})],1):_vm._e()],1)],1),_c('br'),_c('Molded',[_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"3"}},[_c('RadioGroup',{attrs:{"title":"Incluir Produto da Locação?","options":[
          { text: 'Sim', value: true },
          { text: 'Não', value: false } ]},model:{value:(_vm.generateInvoice.isAddProductRent),callback:function ($$v) {_vm.$set(_vm.generateInvoice, "isAddProductRent", $$v)},expression:"generateInvoice.isAddProductRent"}})],1),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"3"}},[_c('RadioGroup',{attrs:{"title":"Incluir Período da Locação?","options":[
          { text: 'Sim', value: true },
          { text: 'Não', value: false } ]},model:{value:(_vm.generateInvoice.isAddPeriodRent),callback:function ($$v) {_vm.$set(_vm.generateInvoice, "isAddPeriodRent", $$v)},expression:"generateInvoice.isAddPeriodRent"}})],1),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"3"}},[_c('RadioGroup',{attrs:{"title":"Incluir Condição de Pagamento?","options":[
          { text: 'Sim', value: true },
          { text: 'Não', value: false } ]},model:{value:(_vm.generateInvoice.isAddPayment),callback:function ($$v) {_vm.$set(_vm.generateInvoice, "isAddPayment", $$v)},expression:"generateInvoice.isAddPayment"}})],1)],1),(!_vm.generateInvoice.isAddProductRent)?_c('EditorHtml',{attrs:{"title":"Descrição","field":"description","required":false,"disabled":_vm.disabled,"markFormDirty":false,"maxLength":500},model:{value:(_vm.generateInvoice.description),callback:function ($$v) {_vm.$set(_vm.generateInvoice, "description", $$v)},expression:"generateInvoice.description"}}):_vm._e()],1),_c('br'),_c('Molded',[_c('EditorHtml',{attrs:{"title":"Observações","field":"observation","required":false,"disabled":_vm.disabled,"markFormDirty":false,"maxLength":500},model:{value:(_vm.generateInvoice.observation),callback:function ($$v) {_vm.$set(_vm.generateInvoice, "observation", $$v)},expression:"generateInvoice.observation"}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"div-title"},[_c('span',{staticClass:"title"},[_vm._v(" Fatura de Locação")])])}]

export { render, staticRenderFns }