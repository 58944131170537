<template>
  <div>
    <b-row>
      <b-col sm="6">
        <b-form-checkbox v-model="showSelectAll" @change="select">
          TODOS PRODUTOS
        </b-form-checkbox>
      </b-col>
      <b-col sm="6">
        <TableTotalRecords :totalRecords="content.totalRecords" />
      </b-col>
    </b-row>
    <Loading type="line" :center="false" v-show="loading" />
    <ScrollBar :minHeight="330" :maxHeight="330">
      <div>
        <table class="table table-responsive-xs">
          <thead>
            <tr>
              <th></th>
              <th class="title-header">Produto</th>
              <th class="title-header text-center">Quantidade</th>
              <th class="title-header text-center">Valor</th>
            </tr>
          </thead>
          <tbody v-for="itemInvoice in content.data" :key="itemInvoice.id">
            <td class="td-checkbox">
              <b-form-checkbox v-model="selected" :value="itemInvoice" />
            </td>
            <td class="td-description">
              <span> {{ itemInvoice.productFullName }} </span>
            </td>
            <td class="text-center td-quantity">
              <span> {{ itemInvoice.quantity }}</span>
            </td>
            <td class="td-total text-center">
              <ItemsRentalTotalization
                :data="{
                  quantity: itemInvoice.quantity,
                  unitValue: itemInvoice.unitValue,
                  totalUnitValue: itemInvoice.totalUnitValue,
                  unitDiscount: itemInvoice.unitDiscount,
                  totalUnitDiscount: itemInvoice.totalUnitDiscount,
                  unitCost: 0,
                  totalUnitCost: 0,
                  totalUnitInsurance: itemInvoice.totalUnitInsurance,
                }"
              />
            </td>
          </tbody>
          <tbody v-show="content.data == 0">
            <tr>
              <td colspan="12">
                <span>Nenhum produto adicionado!</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ScrollBar>
  </div>
</template>

<script>
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import ItemsRentalTotalization from "../../rental/items-rent/ItemsRentalTotalization.vue";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "ItemsRentForInvoice",
  props: {
    rentId: String,
    periodRentId: String,
  },
  components: {
    ScrollBar,
    TableTotalRecords,
    Loading,
    ItemsRentalTotalization,
  },
  data() {
    return {
      content: {
        data: [],
        totalRecords: 0,
      },
      selected: [],
      showSelectAll: false,
      urlGetApi: "/api/v1/rental/items-rent/get-all-for-invoice",
      loading: false,
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "addEvent", "removeLoading"]),
    ...mapActions("generic", ["getApi", "deleteAllApi"]),
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGetApi,
        obj: {
          ...this.baseParams,
          rentId: this.rentId,
          periodRentId: this.periodRentId,
        },
      };
      this.getApi(params).then((response) => {
        this.content.data = response.content.data;
        this.content.totalRecords = response.content.totalRecords;
        this.loading = false;
      });
    },
    create() {
      this.openModal("itemInvoice");
      this.removeLoading(["btnItemsRentCreateUpdate"]);
    },
    update(itemInvoice) {
      this.openModal("itemInvoice");
      this.addEvent({ name: "itemInvoiceUpdate", data: itemInvoice });
    },
    select() {
      this.selected = [];
      if (this.showSelectAll) {
        for (let i in this.content.data) {
          this.selected.push(this.content.data[i]);
        }
      }
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "selectedPeriodRent") this.getAll();
      },
      deep: true,
    },
    selected: {
      handler(selected) {
        this.addEvent({ name: "itemsSelectedForInvoice", data: selected });
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.td-checkbox {
  width: 30px;
}

.td-description {
  max-width: 600px;
}

.td-edit {
  cursor: pointer;
  background-color: #f1f4f9;
}

.td-quantity {
  max-width: 120px;
}

.td-total {
  max-width: 300px;
}

.div-btn {
  margin-top: 15px;
  margin-bottom: 20px;
}

.icon-danger {
  font-size: 20px;
  color: #f0134d;
  cursor: pointer;
}

.icon-danger:hover {
  color: #c70f40;
}
</style>
