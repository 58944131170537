var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-checkbox',{on:{"change":_vm.select},model:{value:(_vm.showSelectAll),callback:function ($$v) {_vm.showSelectAll=$$v},expression:"showSelectAll"}},[_vm._v(" TODOS PRODUTOS ")])],1),_c('b-col',{attrs:{"sm":"6"}},[_c('TableTotalRecords',{attrs:{"totalRecords":_vm.content.totalRecords}})],1)],1),_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"type":"line","center":false}}),_c('ScrollBar',{attrs:{"minHeight":330,"maxHeight":330}},[_c('div',[_c('table',{staticClass:"table table-responsive-xs"},[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"title-header"},[_vm._v("Produto")]),_c('th',{staticClass:"title-header text-center"},[_vm._v("Quantidade")]),_c('th',{staticClass:"title-header text-center"},[_vm._v("Valor")])])]),_vm._l((_vm.content.data),function(itemInvoice){return _c('tbody',{key:itemInvoice.id},[_c('td',{staticClass:"td-checkbox"},[_c('b-form-checkbox',{attrs:{"value":itemInvoice},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('td',{staticClass:"td-description"},[_c('span',[_vm._v(" "+_vm._s(itemInvoice.productFullName)+" ")])]),_c('td',{staticClass:"text-center td-quantity"},[_c('span',[_vm._v(" "+_vm._s(itemInvoice.quantity))])]),_c('td',{staticClass:"td-total text-center"},[_c('ItemsRentalTotalization',{attrs:{"data":{
                quantity: itemInvoice.quantity,
                unitValue: itemInvoice.unitValue,
                totalUnitValue: itemInvoice.totalUnitValue,
                unitDiscount: itemInvoice.unitDiscount,
                totalUnitDiscount: itemInvoice.totalUnitDiscount,
                unitCost: 0,
                totalUnitCost: 0,
                totalUnitInsurance: itemInvoice.totalUnitInsurance,
              }}})],1)])}),_c('tbody',{directives:[{name:"show",rawName:"v-show",value:(_vm.content.data == 0),expression:"content.data == 0"}]},[_c('tr',[_c('td',{attrs:{"colspan":"12"}},[_c('span',[_vm._v("Nenhum produto adicionado!")])])])])],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }