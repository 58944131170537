<template>
  <div>
    <SelectOption buttonSize="large" buttonClassIcon="fas fa-arrow-circle-right" :width="180" :height="190"
      :options="optionsByRule" :showButtonNext="false" v-model="selectedOption" :clickedNext="clickedNext" />
  </div>
</template>
<script>
import SelectOption from "@nixweb/nixloc-ui/src/component/shared/SelectOption.vue";

import { mapGetters } from "vuex";

export default {
  name: "InvoiceOrTaxDocument",
  components: {
    SelectOption,
  },
  props: ["value"],
  data() {
    return {
      options: [
        {
          title: "Fatura",
          titleSize: "17",
          icon: "fa-solid fa-file-invoice",
          iconSize: 40,
          value: 1,
        },
        {
          title: "NFSe",
          titleSize: "17",
          icon: "fa-solid fa-receipt",
          iconSize: 40,
          value: 2,
        },
      ],
      selectedOption: 0,
    };
  },
  computed: {
    ...mapGetters("user", ["hasRule"]),
    optionsByRule() {
      let options = [];
      this.options.forEach((option) => {
        var isRuleTaxDocument = this.hasRule("TaxDocument");
        var isRuleBilling = this.hasRule("Billing");
        if (option.title == "NFSe" && isRuleTaxDocument) options.push(option);
        if (option.title == "Fatura" && isRuleBilling) options.push(option);
      });
      return options;
    },
  },
  watch: {
    selectedOption: {
      handler(selectedOption) {
        this.$emit("input", selectedOption);
      },
      deep: true,
    },
  },
};
</script>